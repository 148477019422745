import circles from "../img/circles.png";
const Circles = () => {
  return (
    <div className="w-[200px] xl:w-[300px] absolute -right-16 -bottom-2 mix-blend-color-dodge animate-pulse duration-75 z-10">
      <img  src={circles} width={260} height={200} className="w-full h-full" alt=""/>
    </div>
  )
};

export default Circles;
