import {NavLink } from "react-router-dom";
import { HiHome, HiUser, HiViewColumns, HiRectangleGroup, HiChatBubbleBottomCenterText, HiEnvelope } from 'react-icons/hi2';

const navData = [
  { name: 'home', to: '/', icon: <HiHome /> },
  { name: 'about', to: '/about', icon: <HiUser /> },
  { name: 'services', to: '/services', icon: <HiViewColumns /> },
  { name: 'work', to: '/work', icon: <HiRectangleGroup /> },
  { name: 'testimonials', to: '/testimonials', icon: <HiChatBubbleBottomCenterText /> },
  { name: 'contact', to: '/contact', icon: <HiEnvelope /> },
]

export default function Navbar() {

    return (
      <nav className='flex flex-col items-center xl:justify-center gap-y-4 fixed h-max bottom-0 mt-auto xl:right-[2%] z-50 top-0 w-full xl:w-16 xl:max-w-md xl:h-screen'>
        <div  className='flex w-full xl:flex-col items-center justify-between xl:justify-center gap-y-10 px-4 md:px-40 xl:px-0 h-[80px] xl:h-max py-8 bg-white/10 backdrop-blur-sm text-3xl xl:text-xl xl:rounded-full'>
        {navData.map((link, index) => {
          return (
            <NavLink to={link.to} key={index} className={({ isActive }) => isActive ? 'text-accent' : 'relative flex items-center group hover:text-accent transition-all duration-300'}>
                <div className='absolute pr-14 right-0 hidden xl:group-hover:flex'>
                    <div className='bg-white relative flex text-primary items-center p-[6px] rounded-[3px]'>
                        <div className='text-[12px] leading-none font-semibold capitalize'>{link.name}</div>
                        <div className='border-solid border-l-white border-l-8 border-y-transparent border-y-[6px] border-r-0 absolute -right-2'></div>
                    </div>
                </div>
                <div>
                    {link.icon}
                </div>
            </NavLink>
          )
        })
      }
      </div>
    </nav>       
    )
}